
















































import { defineComponent, reactive, ref } from '@vue/composition-api'

import callApi from '@/composables/fetchapi'
import { getApiUrl } from '@/inc/app.config'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Container from './Container.vue'
import VTextarea from '@/components/form/VTextarea.vue'
import Upload, { File } from '@/components/form/Upload.vue'
import { NotificationSeverity } from '@/inc/types'

type UploadItem = {
  dirId: string
  fileName: string
  id: string
}

export default defineComponent({
  name: 'payment-proof',
  components: { FeedbackMessage, VTextarea, Upload, Container },
  props: {},

  setup(props, ctx) {
    const feedbackMessage = ref<{ type: NotificationSeverity; htmltext: string } | null>()
    const loanID = ctx.root.$route.params.id
    const isLoading = ref(false)
    const form = reactive({
      files: [] as UploadItem[],
      comment: '',
    })

    const onUpload = (files: File[]) => {
      form.files = files.map(file => {
        const serverId = JSON.parse(file.serverId) || {}
        const values = {
          dirId: serverId.fileId || '',
          fileName: `${file.filename}.${file.fileExtension}`,
          id: file.id,
        }

        return values
      })
    }

    const onSubmit = () => {
      callApi(`${getApiUrl()}/loans/${loanID}/uploads`, { method: 'POST', data: form })
        .then(() => {
          feedbackMessage.value = {
            type: 'success',
            htmltext: 'common.formSuccess',
          }
        })
        .catch(() => {
          feedbackMessage.value = {
            type: 'error',
            htmltext: 'common.formError',
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    return { feedbackMessage, isLoading, onSubmit, onUpload, form }
  },
})
