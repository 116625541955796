


















import { PropType, defineComponent } from '@vue/composition-api'

export type Broker = {
  title?: string
  type?: string
  text?: string
  map?: string
  contact?: string
  phone?: string
}

export default defineComponent({
  name: 'broker-card',
  components: {},
  props: {
    content: {
      type: Object as PropType<Broker>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
