


























































































































































import { defineComponent, PropType, ref } from '@vue/composition-api'

import PaymentProof from '@/components/popups/PaymentProof.vue'
import Alert from '@/components/ui/Alert.vue'
import { Loan } from '@/views/SingleLoan.vue'

export default defineComponent({
  name: 'payments',
  components: { PaymentProof, Alert },
  props: {
    loan: {
      type: Object as PropType<Loan>,
      required: true,
    },
  },

  setup() {
    const paymentProofVisible = ref(false)

    const onDocRequest = () => {
      // SIT?
    }

    return { onDocRequest, paymentProofVisible }
  },
})
