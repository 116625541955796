

































































































































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import VSelect from '@/components/form/VSelect.vue'
import VRadio from '@/components/form/VRadio.vue'
import VInput from '@/components/form/VInput.vue'
import Container from './Container.vue'
import callApi from '@/composables/fetchapi'
import { getApiUrl } from '@/inc/app.config'
import { NotificationSeverity } from '@/inc/types'

export default defineComponent({
  name: 'reimbursement',
  components: { FeedbackMessage, VSelect, VRadio, VInput, Container },
  props: {},

  setup(props, ctx) {
    const feedbackMessage = ref<{ type: NotificationSeverity; htmltext: string } | null>()
    const loanID = ctx.root.$route.params.id
    const { i18n } = useGetters(['i18n'])
    const isLoading = ref(false)
    const form = reactive({
      date: '',
      isPartial: '',
      amount: '',
      choice: '',
      source: '',
      details: '',
    })

    const onSubmit = e => {
      e.preventDefault()
      isLoading.value = true
      callApi(`${getApiUrl()}/loans/${loanID}/rba`, { method: 'post', data: form })
        .then(() => {
          feedbackMessage.value = {
            type: 'success',
            htmltext: 'common.formSuccess',
          }
        })
        .catch(() => {
          feedbackMessage.value = {
            type: 'error',
            htmltext: 'common.formError',
          }
          isLoading.value = false
        })
    }

    return { feedbackMessage, i18n, isLoading, onSubmit, form }
  },
})
