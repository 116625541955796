














import { defineComponent } from '@vue/composition-api'
import { Alert } from '@/inc/types'

export default defineComponent({
  name: 'alert',
  components: {},
  props: {
    content: {
      required: true,
      type: Object as () => Alert,
    },
  },

  setup() {
    return {}
  },
})
