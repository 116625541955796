











































































import { defineComponent, PropType, ref } from '@vue/composition-api'

import { Loan, Type } from '@/views/SingleLoan.vue'
import VTable from '@/components/ui/Table.vue'
import Alert from '@/components/ui/Alert.vue'
import BrokerCard from '@/components/BrokerCard.vue'

export default defineComponent({
  name: 'recap',
  components: { BrokerCard, Alert, VTable },
  props: {
    loan: {
      type: Object as PropType<Loan>,
      required: true,
    },
  },

  setup(props, ctx) {
    const type = ref<Type>(ctx.root.$route.meta.type)

    return { type }
  },
})
