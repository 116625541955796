





















import { gsap } from 'gsap'
import { defineComponent, onMounted, ref } from '@vue/composition-api'

export interface SwitcherItem {
  label: string
  action: () => void
  isActive?: boolean
}

export default defineComponent({
  name: 'switcher',
  components: {},
  props: {
    content: {
      type: Array as () => SwitcherItem[],
      default: () => [],
    },
  },

  setup() {
    const isReady = ref(false)
    const label = ref('')
    const buttons = ref<HTMLElement[] | null>(null)
    const slider = ref<HTMLElement | null>(null)
    const activeBtn = ref<HTMLElement | null | undefined>(null)
    const onClick = (e, action) => {
      setSlider(e.currentTarget)
      action()
    }

    const setSlider = (el: HTMLElement | null, noAnimation = false) => {
      activeBtn.value && activeBtn.value.classList.remove('is-active')

      if (el) {
        activeBtn.value = el
      } else if (!activeBtn.value) {
        activeBtn.value = buttons.value?.find(btn => btn.className.includes('is-active')) || buttons.value?.[0]
      }

      activeBtn.value?.classList.add('is-active')

      const l = activeBtn.value?.querySelector('[data-label]') as HTMLElement
      l && (label.value = l.innerText)

      if (noAnimation) {
        gsap.set(slider.value, {
          x: activeBtn.value?.offsetLeft,
          width: activeBtn.value?.offsetWidth,
        })
      } else if (slider.value) {
        gsap.to(slider.value, {
          duration: 0.5,
          x: activeBtn.value?.offsetLeft,
          width: activeBtn.value?.offsetWidth,
          ease: 'power3.out',
        })
      }
    }

    const onResize = () => {
      setSlider(null, true)
    }

    onMounted(() => {
      setSlider(null, true)
      isReady.value = true
    })

    return { onClick, onResize, label, isReady, buttons, slider }
  },
})
