






















































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { NotificationSeverity } from '@/inc/types'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Container from './Container.vue'
import { docRequest } from '../loan/Docs.vue'

export default defineComponent({
  name: 'email',
  components: { Container, FeedbackMessage },
  props: {
    docId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const loanID = ctx.root.$route.params.id
    const { user } = useGetters('user', ['user'])

    const isLoading = ref(false)
    const feedbackMessage = ref<{ type: NotificationSeverity; htmltext: string } | null>()

    const onClose = (accepted = false) => {
      ctx.emit('close', accepted)
    }

    const onSubmit = () => {
      isLoading.value = true

      docRequest(loanID, props.docId)
        .then(() => {
          onClose(true)
        })
        .catch(() => {
          feedbackMessage.value = {
            type: 'error',
            htmltext: 'common.formError',
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    return {
      onClose,
      isLoading,
      feedbackMessage,
      onSubmit,
      user,
    }
  },
})
