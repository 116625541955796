import { render, staticRenderFns } from "./DeathCertificate.vue?vue&type=template&id=f82f1a6a&scoped=true&"
import script from "./DeathCertificate.vue?vue&type=script&lang=ts&"
export * from "./DeathCertificate.vue?vue&type=script&lang=ts&"
import style0 from "./DeathCertificate.vue?vue&type=style&index=0&id=f82f1a6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f82f1a6a",
  null
  
)

export default component.exports