













































































import { useActions, useGetters } from '@u3u/vue-hooks'
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { Loan, Type } from '@/views/SingleLoan.vue'
import DownloadList from '@/components/DownloadList.vue'
import PaymentProof from '@/components/popups/PaymentProof.vue'
import DeathCertificate from '@/components/popups/DeathCertificate.vue'
import Reimbursement from '@/components/popups/Reimbursement.vue'
import DocRequestConfirmation from '@/components/popups/DocRequestConfirmation.vue'
import callApi from '@/composables/fetchapi'
import { getApiUrl } from '@/inc/app.config'

export function docRequest(loanID: string, docID: string) {
  return callApi(`${getApiUrl()}/loans/${loanID}/doc/${docID}`, { method: 'post' })
}

export default defineComponent({
  name: 'recap',
  components: { DownloadList, PaymentProof, DeathCertificate, Reimbursement, DocRequestConfirmation },
  props: {
    loan: {
      type: Object as PropType<Loan>,
      required: true,
    },
  },

  setup(props, ctx) {
    const { user } = useGetters('user', ['user'])
    const { FETCH_LOAN: fetchLoan } = useActions('user', ['FETCH_LOAN'])
    const type = ref<Type>(ctx.root.$route.meta.type)
    const paymentProofVisible = ref(false)
    const deathCertificateVisible = ref(false)
    const rbaVisible = ref(false)
    const docRequestConfirmationVisible = ref(false)
    const loanID = ctx.root.$route.params.id

    const updateLoan = () =>
      fetchLoan(loanID).then(res => {
        props.loan = res.data
      })
    const docRequestID = ref('')
    const onDocRequest = (id: string) => {
      docRequestID.value = id

      if (user.value.gdprOptin) {
        docRequestConfirmationVisible.value = true
      } else {
        docRequest(loanID, id).then(updateLoan)
      }
    }

    const docRequestConfirmationClose = (accepted: boolean) => {
      docRequestConfirmationVisible.value = false

      if (accepted) {
        fetchLoan(loanID).then(updateLoan)
      }
    }

    return {
      docRequestConfirmationClose,
      docRequestID,
      onDocRequest,
      paymentProofVisible,
      deathCertificateVisible,
      rbaVisible,
      docRequestConfirmationVisible,
      type,
    }
  },
})
