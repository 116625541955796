var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payments"},[_c('div',{staticClass:"payments__header"},[_c('h2',[_vm._v(_vm._s(_vm.$t('singleLoan.payments.title')))]),_c('div',{staticClass:"payments__header__status"},[(_vm.loan.payments.status)?_c('div',{staticClass:"e-label",class:{ 'e-label--orange': _vm.loan.payments.status !== 'OK' }},[_vm._v(" "+_vm._s(_vm.$t(("singleLoan.payments.statusLabel." + (_vm.loan.payments.status))))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t('singleLoan.payments.status'))+" "+_vm._s(_vm.$dayjs(_vm.loan.payments.date).format('DD.MM.YYYY')))])]),_c('hr',{staticClass:"payments__header__hr"}),_c('div',{staticClass:"payments__header__balance"},[_c('span',{staticClass:"payments__header__balance__date h3"},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.payments.balance'))+" "+_vm._s(_vm.$dayjs(_vm.loan.payments.date).format('DD.MM.YYYY'))+" ")]),_c('span',{staticClass:"payments__header__balance__amount"},[_vm._v(" "+_vm._s(_vm.loan.payments.balance)+" € ")])]),_c('hr',{staticClass:"payments__header__hr"})]),(_vm.loan.status === 'CLAIMED')?_c('alert',{attrs:{"content":{
       title: _vm.$t('singleLoan.litigationLoan.title'),
       htmltext: _vm.$t('singleLoan.litigationLoan.text'),
       type: 'warning',
       size: 'big',
     }}}):_vm._e(),(_vm.loan.status === 'LATE')?_c('alert',{attrs:{"content":{
       title: _vm.$t('singleLoan.duePaymentLoan.title'),
       htmltext: _vm.$t('singleLoan.duePaymentLoan.text', {
         date: '< à remplacer >',
       }),
       type: 'warning',
       size: 'big',
     }}}):_vm._e(),_c('div',{staticClass:"payments__list"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('singleLoan.payments.payments'))+"️")]),(_vm.loan.payments)?_c('div',{staticClass:"payments-table-outer pb-xxs"},[_c('table',{staticClass:"payments-table"},[_c('thead',[_c('tr',{staticClass:"payments-table__tr"},_vm._l((_vm.$t('singleLoan.payments.table')),function(th){return _c('th',{key:th,staticClass:"payments-table__th"},[_c('span',[_vm._v(" "+_vm._s(th)+" ")])])}),0)]),_c('tbody',_vm._l((_vm.loan.payments.tr),function(tr,idx){return _c('tr',{key:idx,staticClass:"payments-table__tr"},_vm._l((tr),function(td){return _c('td',{key:td,staticClass:"payments-table__td"},[_vm._v(_vm._s(td))])}),0)}),0)])]):_vm._e()]),_c('div',{staticClass:"payments__list__actions"},[_c('span',{staticClass:"payments__list__actions__title"},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.payments.previous.title'))+" ")]),_c('div',{staticClass:"payments__list__actions__content"},[_c('span',{staticClass:"payments__list__actions__content__description"},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.payments.previous.description'))+" ")]),_c('g-btn',{staticClass:"payments__list__actions__content__cta btn--link",attrs:{"btn":{
             icon: {
               symbol: 'icons-download',
               size: '0 0 22 21',
             },
           },"tag":"button"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onDocRequest($event)}}},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.payments.previous.link'))+" ")])],1)]),_c('hr')]),_c('alert',{attrs:{"content":{
       title: _vm.$t('singleLoan.payments.submit.title'),
       htmltext: _vm.$t('singleLoan.payments.submit.text', {
         date: '< à remplacer >',
       }),
       type: 'warning',
     }}},[_c('g-btn',{staticClass:"list__item__cta btn--outline",attrs:{"btn":{
         icon: {
           symbol: 'icons-upload',
           size: '0 0 22 21',
         },
       },"tag":"button"},nativeOn:{"click":function($event){$event.preventDefault();_vm.paymentProofVisible = true}}},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.payments.submit.btn'))+" ")])],1),(_vm.paymentProofVisible)?_c('PaymentProof',{on:{"close":function($event){_vm.paymentProofVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }