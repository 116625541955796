
































































import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'v-input',
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    label: String,
    id: String,
    checked: Boolean,
    model: [Array, String, Number, Boolean],
    required: {
      type: Boolean,
      default: false,
    },
    value: String,
    name: String,
  },

  setup(props) {
    const isChecked = computed(() => {
      if (props.checked) {
        return props.checked
      }

      return props.model === props.value
    })

    return {
      isChecked,
    }
  },
})
