var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recap"},[_vm._l((_vm.loan.table),function(tableData,index){return _c('div',{key:index,staticClass:"mb-l"},[_c('v-table',{staticClass:"recap__table",attrs:{"content":{
        th: tableData.th,
        tr: tableData.tr,
      }}}),(index === 0 && _vm.loan.status !== 'CLAIMED' && _vm.loan.status !== 'LATE')?_c('p',{staticClass:"m-xs"},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.delayWarning'))+" ")]):_vm._e(),(index === 0)?[(_vm.loan.status === 'CLAIMED')?_c('alert',{staticClass:"mt-s",attrs:{"content":{
          title: _vm.type === 'loan' ? _vm.$t('singleLoan.litigationLoan.title') : _vm.$t('singleLoan.litigationMortgage.title'),
          htmltext: _vm.type === 'loan' ? _vm.$t('singleLoan.litigationLoan.text') : _vm.$t('singleLoan.litigationMortgage.text'),
          type: 'warning',
          size: 'big',
        }}}):_vm._e(),(_vm.loan.status === 'LATE')?_c('alert',{staticClass:"mt-s",attrs:{"content":{
          title: _vm.type === 'loan' ? _vm.$t('singleLoan.duePaymentLoan.title') : _vm.$t('singleLoan.duePaymentMortgage.title'),
          htmltext: _vm.type === 'loan' ? _vm.$t('singleLoan.duePaymentLoan.text') : _vm.$t('singleLoan.duePaymentMortgage.text'),
          type: 'warning',
          size: 'big',
        }}}):_vm._e()]:_vm._e()],2)}),(_vm.loan.broker)?[_c('h2',{staticClass:"mt-m mb-xs recap__broker"},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.broker'))+" ")]),_c('broker-card',{attrs:{"content":_vm.loan.broker}})]:_vm._e(),(_vm.type === 'mortgage')?[_c('h2',{staticClass:"table-title--no-border mb-xxs"},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.contactTitle'))+" ")]),_c('div',{staticClass:"recap__contact"},[_c('h3',[_vm._v(_vm._s(_vm.$t('singleLoan.contact.title')))]),_c('hr'),_c('div',{staticClass:"recap__contact__title"},[_c('strong',[_vm._v("Crefius")]),_vm._v(" "+_vm._s(_vm.$t('singleLoan.contact.handler'))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('singleLoan.contact.copy')))]),_c('g-link',{staticClass:"recap__contact__link",attrs:{"href":"mailto:elantis-gestioncredits@crefius.com","target":"_blank"}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"size":"0 0 24 24","symbol":"icons-mail"}}),_vm._v(" "+_vm._s(_vm.$t('singleLoan.contact.link'))+" ")])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }