





















import { defineComponent } from '@vue/composition-api'

export interface Table {
  th?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tr: [any[]]
}

export default defineComponent({
  name: 'vtable',
  components: {},
  props: {
    content: {
      type: Object as () => Table,
      default: () => ({} as Table),
    },
  },

  setup() {
    return {}
  },
})
