



























































import { defineComponent, onBeforeUnmount, onMounted, ref, set, PropType } from '@vue/composition-api'
import 'filepond/dist/filepond.min.css'
import * as FilePond from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import { upload } from '@/inc/endpoints'
import callApi from '@/composables/fetchapi'

FilePond.registerPlugin(FilePondPluginFileValidateType)

export type File = {
  fileExtension: string
  filename: string
  id: string
  serverId: string
  state: 'loading' | 'success' | 'error'
}

export default defineComponent({
  name: 'upload',
  components: {},
  props: {
    label: String,
    required: Boolean,
    filetypes: {
      type: Array as PropType<string[]>,
      default: () => ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
      required: false,
    },
  },

  setup(props, ctx) {
    let filepond: FilePond.FilePond
    const input = ref<HTMLInputElement>()
    const files = ref<Record<string, File>>({})

    const onRemove = (file: File) => {
      const server = JSON.parse(file.serverId)

      file.state = 'loading'
      callApi(`${upload}/${server.fileId}`, { method: 'DELETE' })
        .then(() => {
          delete files.value[file.id]
          files.value = { ...files.value }
        })
        .catch(() => {
          file.state = 'error'
        })
    }

    const init = () => {
      filepond = FilePond.create(input.value, {
        server: upload,
        name: 'uploads',
        allowMultiple: true,
        required: Boolean(props.required),
        acceptedFileTypes: props.filetypes,
        onpreparefile: file => {
          const props = Object.getOwnPropertyDescriptors(file)
          const f: File = {
            /* eslint-disable */
            // @ts-ignore
            fileExtension: props.fileExtension.get(),
            // @ts-ignore
            filename: props.filenameWithoutExtension.get(),
            // @ts-ignore
            serverId: props.serverId.get(),
            // @ts-ignore
            id: props.id.get(),
            state: 'loading',
            /* eslint-enable */
          }

          set(files.value, f.id, f)
        },
        onprocessfile: (err, file) => {
          const props = Object.getOwnPropertyDescriptors(file)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          files.value[file.id].serverId = props.serverId.get()
          files.value[file.id].state = 'success'
          ctx.emit('upload', Object.values(files.value))
        },
      })
    }

    onMounted(() => {
      init()
    })

    onBeforeUnmount(() => {
      filepond?.destroy()
    })

    return { onRemove, files, input }
  },
})
