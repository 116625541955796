







































































import { defineComponent, ref } from '@vue/composition-api'

export interface DownloadItem {
  id: 'RBA' | 'TAB' | 'AOP' | 'CO4' | 'DEATH' | 'PROOF'
  title: string
  url: string
  fileType: string
  status?: 'ASK' | 'PENDING' | 'OK' | 'DISABLED'
}

export default defineComponent({
  name: 'download-list',
  components: {},
  props: {
    content: {
      type: Array as () => DownloadItem[],
      default: () => [],
    },
  },

  setup(props, ctx) {
    const tag = (item: DownloadItem) => (item.status === 'OK' ? 'a' : 'div')
    const submittedList = ref<string[]>([])
    const errorList = ref<string[]>([])

    const isSubmitted = (id: string) => submittedList.value.some(item => item === id)
    const hasError = (id: string) => errorList.value.some(item => item === id)
    const onDocRequest = (item: DownloadItem) => {
      // RBA = Demande de remboursement anticipé
      if (item.id === 'RBA') {
        ctx.emit('rba')

        return
      }

      ctx.emit('requested', item.id)
    }

    return { hasError, isSubmitted, tag, onDocRequest }
  },
})
