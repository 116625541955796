
















































import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { computed, defineComponent, onBeforeMount, ref } from '@vue/composition-api'

import Payments from '@/components/loan/Payments.vue'
import Docs from '@/components/loan/Docs.vue'
import Recap from '@/components/loan/Recap.vue'
import EmailUsage, { emailSessionUsageKey } from '@/components/popups/EmailUsage.vue'
import Alert from '@/components/ui/Alert.vue'
import Layout from '@/components/Layout.vue'
import Switcher, { SwitcherItem } from '@/components/ui/Switcher.vue'
import VTable, { Table } from '@/components/ui/Table.vue'
import { DownloadItem } from '@/components/DownloadList.vue'
import { Broker } from '@/components/BrokerCard.vue'

export type Type = 'loan' | 'mortgage'
type ViewType = 'recap' | 'docs' | 'payments'

export type Loan = {
  title: string
  status: 'LATE' | 'CLAIMED'
  documents: DownloadItem[]
  contracts: DownloadItem[]
  payments: {
    balance: number
    date: string
    status: string
    th: string[]
    tr: string[][]
  }
  table: Table[]
  broker?: Broker
}

export default defineComponent({
  name: 'single-loan',
  components: {
    Payments,
    Docs,
    Recap,
    Alert,
    Switcher,
    Layout,
    VTable,
    EmailUsage,
  },
  props: {},

  setup(props, ctx) {
    const { user } = useGetters('user', ['user'])
    const { resource } = useState(['resource'])
    const { FETCH_MORTGAGE: fetchMortgage, FETCH_LOAN: fetchLoan } = useActions('user', [
      'FETCH_MORTGAGE',
      'FETCH_LOAN',
    ])
    const isLoading = ref(false)
    const type = ref<Type>(ctx.root.$route.meta.type)
    const view = ref<ViewType>('recap')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loan = ref<Loan>()
    const loanID = ctx.root.$route.params.id

    const switcher: SwitcherItem[] = [
      {
        isActive: true,
        label: ctx.root.$t('singleLoan.switcher.recap') as string,
        action: () => {
          view.value = 'recap'
        },
      },
      {
        label: ctx.root.$t('singleLoan.switcher.documents') as string,
        action: () => {
          view.value = 'docs'
        },
      },
    ]
    const title = computed(
      () => resource.value.i18n?.CREDIT_TYPE?.find(type => type.value === loan.value?.title)?.label || loan.value?.title
    )
    const isEmailUsageVisible = ref(!user.value.gdprOptin && !sessionStorage.getItem(emailSessionUsageKey))

    onBeforeMount(async () => {
      isLoading.value = true

      try {
        if (type.value === 'mortgage') {
          const res = await fetchMortgage(loanID)

          if (res?.data) {
            loan.value = res.data
          }
        }

        if (type.value === 'loan') {
          const res = await fetchLoan(loanID)

          if (res?.data) {
            loan.value = res.data
          }
        }

        if (loan.value?.payments) {
          switcher.push({
            label: ctx.root.$t('singleLoan.switcher.payments') as string,
            action: () => {
              view.value = 'payments'
            },
          })
        }

        isLoading.value = false
      } catch (error) {
        isLoading.value = false
      }
    })

    return {
      user,
      isEmailUsageVisible,
      title,
      resource,
      isLoading,
      loan,
      view,
      switcher,
      type,
    }
  },
})
